
import { IntegrationsService } from '@/services';
import { LawPayAccounts, Account } from '@/types/integrations.type';
import { defineComponent, reactive } from 'vue';

interface State {
  accounts: Account[];
  lawPayAccounts: LawPayAccounts[];
}

const columns = [
  {
    title: 'Prima Bank Account',
    dataIndex: 'accountName',
    key: 'accountName',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    slots: { customRender: 'type' },
  },
  {
    title: 'Status',
    dataIndex: 'lawPayAccount',
    key: 'status',
    slots: { customRender: 'status' },
  },
  {
    title: 'LawPay Bank Account',
    dataIndex: '_id',
    key: 'select',
    slots: { customRender: 'select' },
  },
  {
    title: 'Action',
    key: 'disconnect',
    dataIndex: '_id',
    slots: { customRender: 'disconnect' },
  },
];

export default defineComponent({
  setup() {
    const data = reactive<State>({
      accounts: [],
      lawPayAccounts: [],
    });

    /** Methods */

    /** LawPay bank account status */
    const getLawPayStatus = (status: boolean) => {
      return status ? 'Connected' : 'Disconnected';
    };

    /** LawPay account type */
    const getAccountType = (type: string) => {
      return type === 'operatingAccount'
        ? 'Operating'
        : type === 'trustAccount'
        ? 'Trust'
        : 'E-Check';
    };

    /** Render LawPay accounts by type (in select) */
    const renderAccountsByType = (type: string) => {
      const isTrust = type === 'trustAccount';

      return data.lawPayAccounts.filter(
        account => account.trustAccount === isTrust,
      );
    };

    const renderAccountName = (accountName: string) => {
      if (accountName.length > 25) return accountName.substr(0, 25) + '...';
      return accountName;
    };

    /** Set info for v-model if lawpay account selected */
    const setSelectedLawpayAccounts = () => {
      data.accounts.forEach(account => {
        const lawPayAccounts = data.lawPayAccounts.filter(
          lawPayAccount =>
            account.lawPayAccount !== null &&
            account.lawPayAccount.some(
              lawPayAccountData =>
                lawPayAccountData.lawPayAccount === lawPayAccount.id,
            ),
        );

        if (lawPayAccounts.length) {
          account.lawPayAccountSelected = [];
          lawPayAccounts.forEach(lawPayAccount => {
            account.lawPayAccountSelected.push(lawPayAccount);
          });
        } else account.lawPayAccountSelected = undefined;
      });
    };

    /** Disabled lawpay account */
    const disableLawpayAccount = (lawPayAccountId: string, sync: boolean) => {
      const lawPayAccount =
        data.lawPayAccounts[
          data.lawPayAccounts
            .map(lawpayAccount => lawpayAccount.id)
            .indexOf(lawPayAccountId)
        ];

      if (lawPayAccount && lawPayAccount !== null) {
        data.lawPayAccounts[
          data.lawPayAccounts
            .map(lawpayAccount => lawpayAccount.id)
            .indexOf(lawPayAccountId)
        ].disabled = sync;
      }
    };

    /** Disabled lawpay accounts selected */
    const disableLawpayAccounts = () => {
      const selectedLawpayAccounts = data.accounts.filter(
        account => account.lawPayAccount && account.lawPayAccount.length > 0,
      );

      selectedLawpayAccounts.forEach(account => {
        account.lawPayAccount.forEach(lawPayAccount => {
          // Disable lawPay account
          disableLawpayAccount(lawPayAccount.lawPayAccount, true);
        });
      });
    };

    const executeRequests = async () => {
      try {
        const {
          lawPayAccounts,
          accounts,
        } = await IntegrationsService.getDualAccounts();
        data.accounts = accounts;
        data.lawPayAccounts = lawPayAccounts;

        setSelectedLawpayAccounts();
        disableLawpayAccounts();
      } catch (error) {
        console.error(error);
      }
    };

    executeRequests();

    /** Event from options to sync accounts with lawpay */
    const syncAccount = async (
      lawPayAccount: any,
      trustAccount: any,
      sync: boolean,
    ) => {
      // Mutation sync accounts
      try {
        const primaAccount: any = await IntegrationsService.syncAccount({
          _idPrimaAccount: trustAccount._id,
          idLawPayAccount: lawPayAccount.id,
          type: lawPayAccount.account,
          sync,
        });

        if (primaAccount && primaAccount !== null) {
          // Set Prima account
          data.accounts[
            data.accounts.map(account => account._id).indexOf(primaAccount._id)
          ] = primaAccount;

          disableLawpayAccount(lawPayAccount.id, sync);

          setSelectedLawpayAccounts();
        }
      } catch (error) {
        console.error(error);
      }
    };

    const disconnectAll = async (account: Account) => {
      for (
        let contAccount = 0;
        contAccount < account.lawPayAccount.length;
        contAccount++
      ) {
        const lawPayAccountId =
          account.lawPayAccount[contAccount].lawPayAccount;
        const lawPayAccount = data.lawPayAccounts.filter(
          lawPayAccount => lawPayAccount.id === lawPayAccountId,
        )[0];

        if (lawPayAccount && lawPayAccount !== null)
          syncAccount(lawPayAccount, account, false);
      }
    };

    const setColorTypeAccount = (account: Account, lawPayAccount: any) => {
      return (!lawPayAccount.disabled && lawPayAccount.account === 'eCheck') ||
        (account.lawPayAccount.some(
          lawPayAccountData => lawPayAccountData.id === lawPayAccount.id,
        ) &&
          lawPayAccount.account === 'eCheck')
        ? 'color: blue;'
        : !lawPayAccount.disabled ||
          account.lawPayAccount.some(
            account => account.lawPayAccount === lawPayAccount.id,
          )
        ? 'color: green;'
        : null;
    };

    const setDisableOption = (account: Account, lawPayAccount: any) => {
      return (
        lawPayAccount.disabled &&
        !account.lawPayAccount.some(
          account => account.lawPayAccount === lawPayAccount.id,
        )
      );
    };

    const closeModal = () => {
      window.top.postMessage(
        'close integrations',
        process.env.VUE_APP_PRIMA_URL,
      );
    };

    return {
      data,
      getLawPayStatus,
      getAccountType,
      renderAccountsByType,
      syncAccount,
      closeModal,
      renderAccountName,
      setColorTypeAccount,
      setDisableOption,
      disconnectAll,
      columns,
    };
  },
});
